import './BookAppointmentsErrorPage.less'

import { Button } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { routes } from '../../../../routes'
import { DatacIcon, DatacTitle } from '../../../common'
import { BookingStep } from '../BookAppointmentsSteps'

export const BookAppointmentsErrorPage: React.VFC<{ type: BookingStep }> = ({ type }) => {
  const intlBook = useScopedIntl('subject_dashboard.appointment.book')
  const getIntlPrefix = () => {
    switch (type) {
      case BookingStep.ScheduleNotPublished:
        return 'not_published'
      case BookingStep.NoSlots:
        return 'no_slots'
      case BookingStep.Rejected:
        return 'rejected'
      case BookingStep.AlreadyBooked:
        return 'already_booked'
      default:
        return 'not_available'
    }
  }

  return (
    <div className="subject-dashboard-book-appointment-error-page">
      <DatacIcon name="x" type="red" />
      <DatacTitle>{intlBook(`${getIntlPrefix()}.title`)}</DatacTitle>
      <div>{intlBook(`${getIntlPrefix()}.description`)}</div>
      <Button type="default" onClick={() => navigate(routes.subjectDashboard)}>
        {intlBook(`${getIntlPrefix()}.homepage`)}
      </Button>
    </div>
  )
}
