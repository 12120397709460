import './BookAppointmentsSteps.less'

import classNames from 'classnames'
import React, { Fragment, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { DatacIcon } from '../../../common'

export enum BookingStep {
  Apply = 'apply',
  Verify = 'verify',
  Schedule = 'schedule',
  ScheduleConfirmation = 'schedule_confirmation',
  ScheduleSuccess = 'schedule_success',
  Enroll = 'enroll',
  NotAvailable = 'notAvailable',
  Rejected = 'rejected',
  NoSlots = 'no_slots',
  AlreadyBooked = 'already_booked',
  ScheduleNotPublished = 'schedule_not_published'
}

interface BookAppointmentsStepsProps {
  currentStep: BookingStep
  hasVerify: boolean
}

export const BookAppointmentsSteps: React.VFC<BookAppointmentsStepsProps> = ({ currentStep, hasVerify }) => {
  const intlBook = useScopedIntl('subject_dashboard.appointment.book')
  const [visibleStep, setVisibleStep] = useState(currentStep)
  const steps = [BookingStep.Apply, hasVerify && BookingStep.Verify, BookingStep.Schedule, BookingStep.Enroll].filter(
    s => !!s
  )
  const stepsWithVisibleStepper = [BookingStep.Schedule, BookingStep.ScheduleConfirmation]

  useEffect(() => {
    setVisibleStep(currentStep === BookingStep.ScheduleConfirmation ? BookingStep.Schedule : currentStep)
  }, [currentStep])

  return (
    stepsWithVisibleStepper.includes(currentStep) && (
      <div className="subject-dashboard-book-appointment-steps">
        {steps.map((step, index) => (
          <Fragment key={index}>
            <div
              className={classNames('subject-dashboard-book-appointment-steps__step', {
                current: visibleStep === step
              })}
            >
              <div
                className={classNames(
                  'subject-dashboard-book-appointment-steps__step__indicator',
                  { done: steps.indexOf(visibleStep) > index },
                  { current: step === BookingStep.Schedule }
                )}
              >
                {steps.indexOf(visibleStep) > index ? <DatacIcon name="check" raw /> : index + 1}
              </div>
              <div
                className={classNames('subject-dashboard-book-appointment-steps__step__name', {
                  current: visibleStep === step
                })}
              >
                {intlBook(`step.${step}`)}
              </div>
            </div>
            {step !== BookingStep.Enroll && <div className="subject-dashboard-book-appointment-steps__line" />}
          </Fragment>
        ))}
      </div>
    )
  )
}
